@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300&family=Noto+Sans+JP:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import './mixins.scss';
* {
    padding:0;
    margin:0;
}
p, button {
    font-family:'IBM Plex Mono', monospace;
}
h1 {
    font-family: 'Inter', sans-serif;
}
.App {
    & section {
        text-align:center;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }
    & .section1 {
        background-color:#FBF7EF;
        height:75px;
        font-family: 'IBM Plex Mono', monospace;
        font-size:23px;
        @include mq('phone') {
            font-size:19px;
        }
    }
    & .section2 {
        background-image:url('https://simple-origin-assets.s3-us-west-2.amazonaws.com/SO_Hero_IMG.jpg');
        background-size:cover;
        background-attachment:fixed;
        height:545px;
        &__subsection {
            background-color:rgba(251, 247, 239, 0.75);
            width:93%;
            padding:45px 10px; 
            & h1 {
                font-size:60px;
                letter-spacing: 3px;
                margin:0 17px 60px 17px;
                @include mq('smTablet') {
                    font-size:46px;
                    margin-bottom:60px;
                }
                @include mq('phone') {
                    font-size:40px;
                }
                @include mq('smPhone') {
                    font-size:35px;
                }
            }
            & p {
                margin:0 auto;
                margin-bottom:40px;
                widows:70%;
                line-height:1.5em;
                @include mq('smTablet') {
                    width:425px;
                    margin-bottom:30px;
                }
                @include mq('phone') {
                    width:85%;
                    font-size:14px;
                }
                @include mq('smPhone') {
                    width:90%;
                }
            }
            & p:last-child {
                margin-bottom:0;
            }
        }
    }
    & .section3 {
        background-color:#AABFC0;
        height:450px;
        & p {
            margin-bottom:30px;
            @include mq('smTablet') {
                width:425px;
                margin-bottom:27px;
            }
            @include mq('phone') {
                width:85%;
                font-size:14px;
                margin-bottom:25px;
            }
            @include mq('smPhone') {
                width:90%;
            }
        }
        & p:last-of-type {
            margin-bottom:50px;
        }
        & h1 {
            margin:0 17px 50px 17px;
            letter-spacing:1px;
            font-size:23px;
            font-weight:bold;
            @include mq('smTablet') {
                font-size:20px;
            }
            @include mq('phone') {
                font-size:18px;
                margin-bottom:40px;
            }
            @include mq('smPhone') {
                font-size:17px;
            }
        }
        & h1:first-child {
            margin-top:25px;
            @include mq('phone') {
                margin-top:15px;
            }
        }
    }
    & .section4 {
        background-color:#FBF7EF;
        height:75px;
        @include mq('smTablet') {
            height:110px;
        }
        @include mq('phone') {
            height:145px;
        }
        & p {
            width:580px;
            font-size:13px;
            line-height:1.5em;
            @include mq('smTablet') {
                width:425px;
                margin-bottom:27px;
            }
            @include mq('phone') {
                width:85%;
                font-size:12px;
                margin-bottom:25px;
            }
            @include mq('smPhone') {
                width:90%;
            }
        }
        & .disappearingBreak {
            @include mq('smTablet') {
                display:none;
            }
        }
    }
    & .section5 {
        background-color:white;
        height:175px;
        & button {
            background-color:#556064;
            color:white;
            width:270px;
            height:70px;
            border:0;
            font-size:17px;
            @include mq('phone') {
                width:190px;
            }
        }
    }
}