$breakpoints: (
    'smTablet': 719px,
    'phone': 449px, 
    'smPhone': 349px,
);
@mixin mq($width) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @media only screen and (max-width: $width) {
            @content;
        }
    }
}
